import React from 'react';
import PropTypes from 'prop-types';

const links = {
  codeConduct: 'https://www.contributor-covenant.org/es/version/2/0/code_of_conduct',
  facebook: 'https://www.facebook.com/calzadaCode/',
  instagram: 'https://www.instagram.com/calzada.code/',
  twitter: 'https://twitter.com/CalzadaCode/',
  newsletter: 'https://www.getrevue.co/profile/calzadacode'
}

const icons = {
  codeConduct: 'fas fa-file-contract',
  facebook: 'fab fa-facebook-f',
  instagram: 'fab fa-instagram',
  twitter: 'fab fa-twitter',
  newsletter: 'fas fa-newspaper'
}

const SocialMediaLink = ({ media }) => (
  <a href={ links[media] } target='_blank' rel='noopener noreferrer'>
    <span className='icon is-large is-size-3 has-text-dark'>
      <i className={ icons[media] }/>
    </span>
  </a>
)

SocialMediaLink.propTypes = {
  media: PropTypes.string,
}

export default SocialMediaLink;
