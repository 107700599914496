import React, { useEffect } from "react";
import Layout from '../components/layout';
import SocialMediaLink from '../components/socialMediaLink';
import logo from '../assets/logo.png';
import Typed from 'typed.js';

const sectionStyle = {
  alignItems: "center",
  display: "flex",
  height: "90vh",
  justifyContent: "center",
}

const logoStyle = {
  height: '35%',
  width: 'auto',
};

export default () => {

  useEffect (() => {
    new Typed('#typed', {
      backDelay: 1000,
      stringsElement: '#typedStrings',
      typeSpeed: 100,
      loop: true
    });
  }, []);

  return (
    <Layout>
      <section className='hero' style={ sectionStyle }>
        <img style={logoStyle} src={logo} alt="Calzada Code" />
        <h1 className='title is-size-2 is-size-3-mobile' style={ { margin: '40px', minHeight: '150px' } }>
          Nos gusta hablar de&nbsp;
          <span className='title is-size-2 is-size-3-mobile' id='typed'/>
        </h1>
        <div id='typedStrings'>
          <h1> Tecnología</h1>
          <h1> Software</h1>
          <h1> Diseño de Productos</h1>
          <h1> JavaScript</h1>
          <h1> Ruby</h1>
          <h1> Elixir</h1>
          <h1> PHP</h1>
          <h1> Electron</h1>
          <h1> Desarrollo de videojuegos</h1>
          <h1> Ciencia de datos</h1>
          <h1>  Inteligencia artificial</h1>
          <h1> Buenas prácticas</h1>
          <h1> Agile</h1>
        </div>
        <div className='is-flex' style={ { margin: '40px' } }>
          <SocialMediaLink media='codeConduct'/>
          <SocialMediaLink media='twitter'/>
          <SocialMediaLink media='instagram'/>
          <SocialMediaLink media='facebook'/>
          <SocialMediaLink media='newsletter'/>
        </div>
      </section>
    </Layout>
  );
}
